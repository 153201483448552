import React from "react";
import { useLocation, matchPath, useHistory } from "react-router-dom";
import { Breadcrumbs } from "@skyportal/ui-kit";
import { getBreadcrumbsConfig } from "./config";

const ModuleBreadcrumbs = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const configItem = getBreadcrumbsConfig().find((item) => matchPath(pathname, item.match));

  if (!configItem) {
    return null;
  }

  return <Breadcrumbs config={configItem} onBreadcrumbClick={history.push} />;
};

export default ModuleBreadcrumbs;
