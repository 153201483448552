// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pricelist_styles_card__4sIF3 {\n  position: relative;\n  max-width: 800px;\n  padding: 8px;\n}\n\n.pricelist_styles_tableBlock__uavJl {\n  margin-bottom: 8px;\n  overflow-x: auto;\n}\n\n.pricelist_styles_tableBlock__uavJl::-webkit-scrollbar {\n  width: 3px;\n  height: 3px;\n  border-radius: 3px;\n}\n\n.pricelist_styles_tableBlock__uavJl::-webkit-scrollbar-thumb {\n  border-radius: 3px;\n  background-color: rgb(0 0 0 / 80%);\n}\n\n.pricelist_styles_tableBlock__uavJl::-webkit-scrollbar-track {\n  border-radius: 3px;\n  background-color: rgb(0 0 0 / 10%);\n}\n", "",{"version":3,"sources":["webpack://./src/components/PricelistTable/styles.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,UAAU;EACV,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,kCAAkC;AACpC;;AAEA;EACE,kBAAkB;EAClB,kCAAkC;AACpC","sourcesContent":[".card {\n  position: relative;\n  max-width: 800px;\n  padding: 8px;\n}\n\n.tableBlock {\n  margin-bottom: 8px;\n  overflow-x: auto;\n}\n\n.tableBlock::-webkit-scrollbar {\n  width: 3px;\n  height: 3px;\n  border-radius: 3px;\n}\n\n.tableBlock::-webkit-scrollbar-thumb {\n  border-radius: 3px;\n  background-color: rgb(0 0 0 / 80%);\n}\n\n.tableBlock::-webkit-scrollbar-track {\n  border-radius: 3px;\n  background-color: rgb(0 0 0 / 10%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "pricelist_styles_card__4sIF3",
	"tableBlock": "pricelist_styles_tableBlock__uavJl"
};
export default ___CSS_LOADER_EXPORT___;
